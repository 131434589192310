/*
============================
Fonts
============================
*/

@import url("https://fonts.googleapis.com/css?family=Catamaran:400,700|Grand+Hotel");

/*
============================
Variables
============================
*/
:root {
  /*? Colors */
  --clr-primary: #c51f1f;
  --clr-primary-light: #9c3015;
  --clr-grey-1: #102a42;
  --clr-grey-5: #617d98;
  --clr-grey-10: #f1f5f8;
  --clr-white: #fff;

  /*? Font-Family */
  --ff-primary: "Catamaran", sans-serif;
  --ff-secondary: "Grand Hotel", sans-serif;

  /*? General */
  --transition: all 0.3s linear;
  --transition-delay: all 0.3s linear 0.4s;
  --spacing: 0.25rem;
  --border-rad: 0.5rem;
}

/*
============================
Global Styles
============================
*/

/*? CSS Reset*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--ff-primary);
  background-color: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-primary);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  transition: var(--transition);
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.btn:hover {
  color: var(--clr-primary);
  background: var(--clr-primary-light);
}

/*! Flaot Clearfix Hack 
The clearfix hack prevent elements from floating around the items where the float was used.

*/

.clearfix::after,
.clearfix::before {
  content: "";
  clear: both;
  display: table;
}

.section-title h3 {
  font-family: var(--ff-secondary);
  color: var(--clr-primary);
}

.section-title {
  margin-bottom: 2rem;
}

.section-center {
  padding: 4rem 0;
  width: 85vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
    padding: 4rem 1rem;
  }
}

/*
================================
SCREEN SIZES
================================
*/

@media screen and (min-width: 800px) {
  body {
    font-size: 1rem;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.25;
  }
}
